import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

//Images to display
// import BillingImage from "../../../assets/images/invoice.png";
// import InventoryImage from "../../../assets/images/inventory.png";
// import PLImage from "../../../assets/images/pl.png";
// import LedgerImage from "../../../assets/images/ledger.png";
import InvoiceImage from "../../../assets/images/invoice.png";
import CheckoutImage from "../../../assets/images/checkout.png";
import HomeImage from "../../../assets/images/home.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const paginationStyles = {
  bullet: {
    backgroundColor: "white", // Default bullet color
    width: "15px",
    height: "10px",
    borderRadius: "8px",
    margin: "0 4px",
  },
};

function FeatureSwiper() {
  return (
    <Swiper
      slidesPerView={1}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          const style = paginationStyles.bullet;
          return `<span class="${className}" style="background-color: ${style.backgroundColor}; width: ${style.width}; height: ${style.height}; border-radius: ${style.borderRadius}; margin: ${style.margin};"></span>`;
        },
      }}
      modules={[Pagination, Autoplay]}
      className="featureSwiper"
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      //   onSlideChange={() => console.log("slide change")}
      //   onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={HomeImage}
            alt="Showing billing section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh] 2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Check Product Availability, Quantity Availability with Easy Order Management.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={InvoiceImage}
            alt="Showing inventory section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh]  2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Manage your invoices effortlessly and download them with a single click.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={CheckoutImage}
            alt="Showing PL section"
            className="block w-[80%] 2xl:w-[80%] 3xl:w-[65%] bg-white h-[40vh] xl:h-[45vh]  2xl:h-[50vh]"
          />
          <p className="text-md xl:text-lg mt-[40px] pb-[7vh] text-white max-w-[50%] text-center font-medium">
            Easily select between pick-up or delivery options and choose the ideal time slot for your order.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default FeatureSwiper;
